import React from 'react'
import Img from 'gatsby-image'
import RatingImg from '../../data/images/ratings.png'
import { TestimonialWrap, Author, AuthorImg, AuthorInfo, Content } from './testimonial.stc'

const Testimonial = ({ title, review, author }) => {
    return (
        <TestimonialWrap>
            <Author>
                <AuthorImg>
                    <Img fixed={author.image.childImageSharp.fixed} alt="Author" />
                </AuthorImg>
                <AuthorInfo>
                    <h6>{author.name}</h6>
                    <p>@{author.name}</p>
                </AuthorInfo>
            </Author>
            <Content>
                <h4>{title}</h4>
                <p>{review}</p>
                <div className="rating">
                    <img src={RatingImg} alt="ratings" />
                </div>
            </Content>
        </TestimonialWrap>
    )
}

export default Testimonial
