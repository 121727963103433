import styled from "styled-components";
import { themeGet } from '@styled-system/theme-get'
import HeroBG from '../../../data/images/preview-banner-shape.png'
import { device } from '../../../theme'

export const HeroWrapper = styled.section`
    padding-top: 150px;
    padding-bottom: 50px;
    background-color: #242323;
    ${device.medium}{
        background: url(${HeroBG}) no-repeat scroll left center;
        background-size: 100% 100%;
    }
    ${device.large}{
        padding-top: 190px;
        .col-xl-4{
            flex: 0 0 40%;
            max-width: 40%;
        }
        .col-xl-8{
            flex: 0 0 60%;
            max-width: 60%;
        }
    }
    ${device.xlarge}{
        height: 800px;
        .col-xl-4{
            flex: 0 0 40%;
            max-width: 40%;
        }
        .col-xl-8{
            flex: 0 0 60%;
            max-width: 60%;
        }
    }
    ${device.xxlarge}{
        height: 960px;
        padding-top: 185px;
        .col-xl-4{
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
        }
        .col-xl-8{
            flex: 0 0 66.666667%;
            max-width: 66.666667%;
        }
    }

`;

export const HeroContent = styled.div`
    padding-left: 0;
    ${device.xsmall}{
        padding-left: 60px;
    }
    ${device.small}{
        padding-left: 84px;
    }
    ${device.medium}{
        padding-left: 0;
    }
    ${device.xxlarge}{
        padding-left: 80px;
    }
    h1{
        color: #fff;
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 20px;
        ${device.medium}{
            font-size: 45px;
        }
        ${device.large}{
            font-size: 50px;
        }
        ${device.xlarge}{
            font-size: 58px;
            margin-bottom: 30px;
        }
    }
    p{
        color: #fff;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 30px;
        ${device.medium}{
            font-size: 25px;
        }
        ${device.large}{
            font-size: 30px;
            margin-bottom: 50px;
        }
        ${device.xlarge}{
            font-size: 37px;
            margin-bottom: 75px;
        }
        ${device.xxlarge}{
            margin-bottom: 143px;
        }
        span{
            color: ${themeGet("colors.hoverColor")};
        }
    }
`;

export const HeroImages = styled.div`
    margin-top: 20px;
    ${device.medium}{
        margin: auto;
    }
`;
