import React from "react"
import SEO from '../components/seo'
import Layout from "../containers/layout/layout"
import Header from '../containers/layout/header'
import Footer from '../containers/layout/footer'
import HeroDemoWrapper from '../containers/home/hero-demo-wrapper'
import HeroArea from '../containers/home/hero-area'
import DemoArea from '../containers/home/demo-area'
import HowWorkArea from '../containers/home/how-work-area'
import FeaturesArea from '../containers/home/features-area'
import FaqArea from '../containers/home/faq-area'
import TestimonialArea from '../containers/home/testimonial-area'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Header transparent />
    <div className="main-content">
      <HeroDemoWrapper>
        <HeroArea />
        <DemoArea />
      </HeroDemoWrapper>
      <HowWorkArea />
      <FeaturesArea />
      <FaqArea />
      <TestimonialArea />
    </div>
    <Footer />
  </Layout>
)

export default IndexPage
