import styled from "styled-components";
import { device } from '../../../theme'

export const DemoWrapper = styled.section`
    padding-top: 51px;
    padding-bottom: 37px;
    ${device.xsmall}{
        padding-bottom: 52px;
    }
    ${device.small}{
        padding-top: 78px;
        padding-bottom: 34px;
    }
    ${device.medium}{
        padding-top: 70px;
        padding-bottom: 15px;
    }
    ${device.large}{
        padding-top: 88px;
        padding-bottom: 25px;
    }
`;