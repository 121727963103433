import styled from 'styled-components'
import { device } from '../../../theme'
import { themeGet } from '@styled-system/theme-get'

export const HowWorksWrap = styled.section`
    padding-top: 53px;
    padding-bottom: 60px;
    ${device.medium}{
        padding-top: 69px;
        padding-bottom: 80px
    }
    ${device.large}{
        padding-top: 88px;
        padding-bottom: 100px;
    }
`;

export const TitleWrapper = styled.div`
    text-align: center;
    margin-bottom: 45px;
    p{
        font-size: 20px;
        font-weight: 300;
        margin-top: 10px;
        a{
            color: ${themeGet("colors.textColor")};
        }
    }
`;

export const ImageWrap = styled.div`
    position: relative;
    img{
        border-radius: 5px;
    }
`;

export const VideoBtn = styled.button`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    border: none;
    width: 130px;
    height: 130px;
    ${device.small}{
        width: 170px;
        height: 170px;
    }
    img{
        transform: scale(.8);
        transition: all .3s;
    }
    p{
        color: #fff;
        text-transform: uppercase;
        line-height: 1;
        margin-top: -15px;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1px;
    }
    &:hover{
        img{
            transform: scale(1);
        }
    }
`;