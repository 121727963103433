import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import { useStaticQuery, graphql } from 'gatsby'
import parse from 'html-react-parser'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import SectionTitle from '../../../components/shared/section-title'
import AccordionWrap from '../../../components/shared/accordion'
import { FaqWrapper, FaqWrap } from './faq-area.stc'

const FaqArea = () => {
    const getFaqData = useStaticQuery(graphql`
        query GET_FAQ_DATA {
            dataJson(id: {eq: "faq-area-content"}) {
                title
                items {
                    id
                    title
                    desc
                }
            }
        }
    `)
    const { title, items } = getFaqData.dataJson
    return (
        <FaqWrapper>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10} xl={6}>
                        <FaqWrap>
                            <SectionTitle
                                mb="41px"
                                title={title}
                            />
                            <AccordionWrap>
                                <Accordion allowZeroExpanded preExpanded={[0]}>
                                    {
                                        items.map((el, index) => {
                                            return (
                                                <AccordionItem id={el.id} key={index}>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            {parse(el.title)}
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        {parse(el.desc)}
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            )
                                        })
                                    }
                                </Accordion>
                            </AccordionWrap>
                        </FaqWrap>
                    </Col>
                </Row>
            </Container>
        </FaqWrapper>
    )
}

export default FaqArea
