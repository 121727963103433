import React from 'react'
import { FaReact, FaCss3Alt } from "react-icons/fa";
import { FeatureWrap } from './feature.stc'

const Feature = ({ icon, title, desc, ...restProps }) => {
    return (
        <FeatureWrap {...restProps}>
            <div className="icon">
                {icon && icon !== "FaReact" && icon !== "css" && <i data-feather={icon}></i>}
                {icon && icon === "FaReact" && icon !== "css" && <FaReact />}
                {icon && icon !== "FaReact" && icon === "css" && <FaCss3Alt />}
            </div>
            <h4>{title}</h4>
            <p>{desc}</p>
        </FeatureWrap>
    )
}

export default Feature
