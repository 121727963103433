import styled from "styled-components";
import { space } from 'styled-system'
import { device } from '../../theme'

export const FeatureWrap = styled.div`
    ${space}
    padding: 30px 35px 20px;
    background: #fff;
    border-radius: 5px;
    height: 100%;
    ${device.xlarge}{
        padding: 40px 50px 20px;
    }
    .icon {
        margin-bottom: 20px;
        svg{
            width: 54px;
            height: 54px;
            stroke-width: 1 !important;
        }
    }
    h4{
        color: #090606;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 8px;
    }
    p{
        color: #090606;
        font-size: 14px;
        line-height: 26px;
        margin-top: 14px;
    }
`;