import styled from "styled-components";
import { device } from '../../../theme'

export const FooterWrap = styled.footer`
    padding-top: 63px;
    padding-bottom: 70px;
    position: relative;
    overflow: hidden;
    &:before{
        position: absolute;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        bottom: -180px;
        width: 300px;
        height: 300px;
        border-radius: 50%;
        background-color: rgb( 247, 195, 207 );
        opacity: 0.149;
        ${device.xsmall}{
            bottom: -422px;
            width: 500px;
            height: 500px;
        }
        ${device.medium}{
            bottom: -510px;
            width: 600px;
            height: 600px;
        }
        ${device.large}{
            bottom: -600px;
            width: 696px;
            height: 696px;
        }
    }
    ${device.medium}{
        padding-top: 100px;
        padding-bottom: 115px;
    }
`;

export const FooterContent = styled.div`
    text-align: center;
    p{
        br{
            display: none;
            ${device.small}{
                display: block;
            }
        }
    }
`;