import styled from "styled-components";
import { themeGet } from '@styled-system/theme-get'
import { device } from '../../../theme'

export const FeaturesWrapper = styled.section`
    padding-top: 52px;
    padding-bottom: 31px;
    background-color: ${themeGet("colors.themeColor")};
    position: relative;
    overflow: hidden;
    .item{
        margin-bottom: 30px;
    }
    &:before{
        position: absolute;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        top: -180px;
        width: 300px;
        height: 300px;
        border-radius: 50%;
        background-color: rgb( 247, 195, 207 );
        opacity: 0.149;
        ${device.xsmall}{
            top: -375px;
            width: 500px;
            height: 500px;
        }
        ${device.medium}{
            top: -440px;
            width: 600px;
            height: 600px;
        }
        ${device.large}{
            top: -505px;
            width: 696px;
            height: 696px;
        }
    }
    ${device.small}{
        padding-top: 49px;
    }
    ${device.medium}{
        padding-top: 67px;
        padding-bottom: 50px;
    }
    ${device.large}{
        padding-top: 87px;
        padding-bottom: 70px;
    }
`; 