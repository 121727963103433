import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get'

export const AccordionWrapper = styled.div`
    .accordion {
        border-width: 0px;
        border-radius: 0;
        &__item{
            border-bottom: none;
            border: none !important;
            &:not(:last-child){
                margin-bottom: 30px;
            }
        }
        &__heading{
            padding: 0;
            border-bottom: 0;
        }
        &__button{
            font-size: 18px;
            padding: 0;
            padding-bottom: 5px;
            background: none;
            font-weight: 500;
            color: ${themeGet("colors.headingColor")};
            border-bottom: 2px solid #d6d6d6;
            outline: none;
            &:before{
                display: none;
            }
            &[aria-expanded="true"]{
                border-bottom-color: ${themeGet("colors.hoverColor")};
            }
        }
        &__panel{
            padding: 10px 0 0;
            p{
                line-height: 26px;
                font-size: 15px;
                &:not(:last-child){
                    margin-bottom: 10px;
                }
            }
            a{
                color: ${themeGet("colors.hoverColor")};
            }
        }
    }
`;
