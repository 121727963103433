import styled from 'styled-components'
import { space, layout, typography } from 'styled-system'
import { device } from '../../../theme'

export const SectionTitleWrap = styled.div`
    ${space}
    ${layout}
    ${typography}
    h3{
        font-size: 25px;
        font-weight: 400;
        br{
            display: none;
        }
        ${device.small}{
            font-size: 30px;
            font-weight: 300;
            br{
                display: block;
            }
        }
        ${device.small}{
            font-size: 35px;
        }
        ${device.medium}{
            font-size: 38px;
        }
        ${device.large}{
            font-size: 40px;
        }
        ${device.xlarge}{
            font-size: 45px;
        }
    }
    p{
        font-size: 16px;
        margin-top: 10px;
        font-weight: 400;
        br{
            display: none;
            ${device.xsmall}{
                display: block;
            }
        }
        ${device.small}{
            font-size: 20px;
            font-weight: 300;
        }
    }
`;