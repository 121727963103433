import React from 'react'
import Anchor from '../shared/anchor'
import Image from '../image'
import { DemoWrap } from './demo.stc'

const Demo = ({ title, link, image, ...restProps }) => {
    return (
        <DemoWrap {...restProps}>
            <Image fluid={image.childImageSharp.fluid} alt="demo" />
            <h4>{title}</h4>
            {link && <Anchor target="_blank" path={link}>Demo Link</Anchor>}
        </DemoWrap>
    )
}

export default Demo
