import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useStaticQuery, graphql } from 'gatsby'
import { FiChevronRight } from "react-icons/fi";
import Button from '../../../components/shared/button'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import {
    FooterWrap,
    FooterContent,
} from './footer.stc'

const Footer = (props) => {
    const getFooterData = useStaticQuery(graphql`
        query GET_FOOTER_DATA {
            site {
                siteMetadata {
                    purchase
                }
            }
        }      
    `)

    const { purchase } = getFooterData.site.siteMetadata

    const { btncss, headingCSS, headingTextCSS } = props
    return (
        <FooterWrap>
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} lg={6}>
                        <FooterContent>
                            <Heading {...headingCSS}>Let's go to <Text {...headingTextCSS}>Purchase</Text></Heading>
                            <Text>Purchase The Fatima and Make Your Site <br /> super faster and easy.</Text>
                            <Button
                                to={purchase}
                                {...btncss} icon={<FiChevronRight />}>Purchase Now</Button>
                        </FooterContent>
                    </Col>
                </Row>
            </Container>
        </FooterWrap>
    )
}

Footer.defaultProps = {
    btncss: {
        varient: 'contained',
        shape: 'rounded',
        btnsize: 'large',
        mt: ['30px', null, null, '35px', '46px'],
        target: '_blank',
        iconcss: {
            pl: '8px'
        }
    },
    headingCSS: {
        as: 'h2',
        fontSize: ['25px', '30px', '35px', '40px', '43px', '45px'],
        fontWeight: 300,
        mb: '20px'
    },
    headingTextCSS: {
        as: 'span',
        fontWeight: 700
    }
}

export default Footer
