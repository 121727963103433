import styled from "styled-components";
import { themeGet } from '@styled-system/theme-get'
import { device } from '../../../theme'

export const TestimonialWrap = styled.section`
    padding-top: 53px;
    padding-bottom: 60px;
    background: ${themeGet("colors.themeColor")};
    position: relative;
    overflow: hidden;
    &:before{
        position: absolute;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        bottom: -180px;
        width: 300px;
        height: 300px;
        border-radius: 50%;
        background-color: rgb( 247, 195, 207 );
        opacity: 0.149;
        ${device.xsmall}{
            bottom: -420px;
            width: 500px;
            height: 500px;
        }
        ${device.medium}{
            bottom: -530px;
            width: 600px;
            height: 600px;
        }
        ${device.large}{
            bottom: -600px;
            width: 696px;
            height: 696px;
        }
    }
    ${device.medium}{
        padding-top: 69px;
        padding-bottom: 80px;
    }
    ${device.large}{
        padding-top: 88px;
        padding-bottom: 100px;
    }
`;