import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useStaticQuery, graphql } from 'gatsby'
import SectionTitle from '../../../components/shared/section-title'
import Feature from '../../../components/feature'
import { FeaturesWrapper } from './features-area.stc'


const FeaturesArea = () => {
    const getFeaturesData = useStaticQuery(graphql`
        query GET_FEATURES_DATA {
            dataJson(id: {eq: "features-area-content"}) {
                title
                items {
                    title
                    desc
                    icon
                }
            }
        }
    `)
    const { title, items } = getFeaturesData.dataJson
    return (
        <FeaturesWrapper>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            mb="43px"
                            title={title}
                        />
                    </Col>
                </Row>
                <Row>
                    {items && items.map((item, i) => (
                        <Col sm={6} lg={4} xl={3} className="item" key={`feature-${i}`}>
                            <Feature
                                icon={item.icon}
                                title={item.title}
                                desc={item.desc}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </FeaturesWrapper>
    )
}

export default FeaturesArea
