import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useStaticQuery, graphql } from 'gatsby'
import SectionTitle from '../../../components/shared/section-title'
import DemoItem from '../../../components/demo'
import { DemoWrapper } from './demo-area.stc'

const DemoArea = () => {
    const demoData = useStaticQuery(graphql`
        query GET_DEMOS {
            dataJson(id: {eq: "demo-area-content"}) {
                title
                subtitle
                items {
                    id
                    title
                    link
                    image {
                        childImageSharp {
                            fluid(maxWidth: 560, maxHeight: 456, quality: 100, srcSetBreakpoints: 6) {
                                ...GatsbyImageSharpFluid
                                presentationWidth
                                presentationHeight
                            }
                        }
                    }
                }
            }   
        }
    `)
    const { title, subtitle, items } = demoData.dataJson
    return (
        <DemoWrapper id="features">
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            mb={["22px", null, "4px", "20px"]}
                            title={title}
                            subtitle={subtitle}
                        />
                    </Col>
                </Row>
                <Row>
                    {items && items.map((item, i) => (
                        <Col sm={6} lg={4} key={`demo-${i}`}>
                            <DemoItem
                                mb={["20px", null, "40px", "51px", "60px", "70px"]}
                                title={item.title}
                                link={item.link}
                                image={item.image}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </DemoWrapper>
    )
}

export default DemoArea
