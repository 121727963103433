import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useStaticQuery, graphql } from 'gatsby'
import parse from 'html-react-parser'
import Image from '../../../components/image'
import Button from '../../../components/shared/button'
import { HeroWrapper, HeroContent, HeroImages } from './hero-area.stc'

const HeroArea = () => {
    const HeroData = useStaticQuery(graphql`
        query GET_HERO_DATA {
            dataJson(id: {eq: "hero-area-content"}) {
                title
                subtitle
                mockup {
                    childImageSharp {
                        fluid(maxWidth: 1080, maxHeight: 725, quality: 100, srcSetBreakpoints: 6) {
                            ...GatsbyImageSharpFluid
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }      
    `);
    const { title, subtitle, mockup } = HeroData.dataJson
    return (
        <HeroWrapper>
            <Container>
                <Row className="align-items-center">
                    <Col lg={5} xl={4}>
                        <HeroContent>
                            {title && <h1>{parse(title)}</h1>}
                            {subtitle && <p>{parse(subtitle)}</p>}
                            <Button
                                varient="contained"
                                btnsize="medium"
                                shape="rounded"
                                p={['5px 15px', '5px 20px', '5px 25px']}
                                letterSpacing='1px'
                                to="#features">View Demo</Button>
                        </HeroContent>
                    </Col>
                    <Col lg={7} xl={8}>
                        <HeroImages>
                            <Image fluid={mockup.childImageSharp.fluid} alt="Mockup" />
                        </HeroImages>
                    </Col>
                </Row>
            </Container>
        </HeroWrapper>
    )
}

export default HeroArea
