import styled from "styled-components";
import { themeGet } from '@styled-system/theme-get'
import { device } from '../../theme'

export const TestimonialWrap = styled.div`
    background: #fff;
    padding: 30px;
    min-height: 420px;
    border-radius: 5px;
    ${device.xsmall}{
        height: 335px;
        min-height: 335px;
    }
    ${device.small}{
        height: 415px;
    }
    ${device.medium}{
        height: 390px;
    }
    ${device.large}{
        height: 460px;
    }
    ${device.xlarge}{
        height: 455px;
        padding: 40px;
    }
    ${device.xxlarge}{
        height: 385px;
    }
`;

export const Author = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    ${device.small}{
        margin-bottom: 20px;
    }
`

export const AuthorImg = styled.figure`
    margin-right: 10px;
`

export const AuthorInfo = styled.div`
    h6{
        font-size: 14px;
        line-height: 1.22;
        font-weight: 700;
        margin-bottom: 4px;
    }
    p{
        font-weight: 300;
        color: ${themeGet("colors.hoverColor")};
    }
`;

export const Content = styled.div`
    h4{
        font-size: 16px;
        margin-bottom: 15px;
    }
    p{
        line-height: 24px;
    }
    .rating{
        margin-top: 30px;
    }
`;
