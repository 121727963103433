import styled from "styled-components";
import { themeGet } from '@styled-system/theme-get'
import { device } from '../../../theme'

export const FaqWrapper = styled.section`
    padding-top: 52px;
    padding-bottom: 60px;
    ${device.small}{
        padding-top: 60px;
    }
    ${device.medium}{
        padding-top: 80px;
        padding-top: 80px;
    }
    ${device.large}{
        padding-top: 100px;
        padding-top: 100px;
    }
    .col-xl-6{
        ${device.large}{
            flex: 0 0 65%;
            max-width: 65%;
        }
        ${device.xlarge}{
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
`;

export const FaqWrap = styled.div`
    ${device.small}{
        padding: 40px 60px 50px;
        border: 2px solid ${themeGet("colors.themeColor")};
        border-radius: 4px;
    }
    ${device.medium}{
        padding: 50px 78px 78px;
    }
`;