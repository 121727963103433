import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useStaticQuery, graphql } from 'gatsby'
import SectionTitle from '../../../components/shared/section-title'
import Swiper from '../../../components/shared/swiper'
import Testimonial from '../../../components/testimonial'
import { TestimonialWrap } from './testimonial-area.stc'


const TestimonialArea = ({ slider }) => {
    const getTestimonialData = useStaticQuery(graphql`
        query GET_TESTIMONIAL_DATA {
            dataJson(id: {eq: "testimonial-area-content"}) {
                title
                items {
                    id
                    title
                    review
                    author {
                        name
                        image {
                            childImageSharp {
                                fixed(width: 50, height: 50, quality: 100) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    const { title, items } = getTestimonialData.dataJson
    return (
        <TestimonialWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            mb="41px"
                            title={title}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={10}>
                        <Swiper settings={slider}>
                            {items && items.map((item, i) => (
                                <div className="item" key={`testimonial-${i}`}>
                                    <Testimonial
                                        title={item.title}
                                        review={item.review}
                                        author={item.author}
                                    />
                                </div>
                            ))}

                        </Swiper>
                    </Col>

                </Row>
            </Container>
        </TestimonialWrap>
    )
}

TestimonialArea.defaultProps = {
    slider: {
        slidesPerView: 1,
        spaceBetween: 30,
        customArrows: true,
        navStyle: 2,
        loop: true,
        breakpoints: {
            1200: {
                slidesPerView: 3,
                spaceBetween: 30
            },
            992: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 10
            }
        }
    }
}

export default TestimonialArea
