import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import { Container } from 'reactstrap';
import { FiChevronRight } from "react-icons/fi";
import Logo from '../../../components/logo'
import Button from '../../../components/shared/button'
import {
    HeaderWrap,
    HeaderOuter,
    HeaderInner,
    HeaderMain,
    HeaderCol,
    HeaderElement,
    FixedHeaderHeight
} from './header.stc'

const Header = (props) => {
    const getHeaderData = useStaticQuery(graphql`
        query GET_HEADER_DATA {
            site {
                siteMetadata {
                    purchase
                }
            }
        }      
    `)

    const { purchase } = getHeaderData.site.siteMetadata

    const [fixedHeaderHeight, setFixedHeaderHeight] = useState(0);
    const [sticky] = useState(false);
    const headerRef = useRef(null);
    const fixedRef = useRef(null);

    useEffect(() => {
        setFixedHeaderHeight(fixedRef.current.clientHeight);
    }, []);

    const { transparent, btncss } = props;
    return (
        <Fragment>
            <HeaderWrap transparent={transparent} ref={headerRef}>
                <HeaderOuter>
                    <HeaderInner transparent={transparent} ref={fixedRef} isSticky={sticky}>
                        <Container>
                            <HeaderMain>
                                <HeaderCol left>
                                    <Logo transparentBG={transparent && !sticky} />
                                </HeaderCol>
                                <HeaderCol right>
                                    <HeaderElement>
                                        <Button to={purchase} {...btncss} icon={<FiChevronRight />}>Purchase Now</Button>
                                    </HeaderElement>
                                </HeaderCol>
                            </HeaderMain>
                        </Container>
                    </HeaderInner>
                    <FixedHeaderHeight height={fixedHeaderHeight} />
                </HeaderOuter>
            </HeaderWrap>
        </Fragment>
    )
}

Header.defaultProps = {
    btncss: {
        varient: 'contained',
        shape: 'rounded',
        btnsize: 'large',
        target: '_blank',
        iconcss: {
            pl: '8px'
        }
    }
}

export default Header;