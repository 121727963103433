import React from 'react'
import PropTypes from 'prop-types'
import { SectionTitleWrap } from './section-title.stc'

const SectionTitle = ({ title, subtitle, ...props }) => {
    return (
        <SectionTitleWrap {...props}>
            {title && <h3 dangerouslySetInnerHTML={{ __html: title }} />}
            {subtitle && <p dangerouslySetInnerHTML={{ __html: subtitle }} />}
        </SectionTitleWrap>
    )
}

SectionTitle.propTypes = {
    textAlign: PropTypes.oneOf(['left', 'center', 'right'])
}

SectionTitle.defaultProps = {
    textAlign: 'center'
}
export default SectionTitle;