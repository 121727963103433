import React, { Fragment, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useStaticQuery, graphql } from 'gatsby'
import Image from '../../../components/image'
import SectionTitle from '../../../components/shared/section-title'
import ModalVideo from '../../../components/shared/modal-video'
import videoBtn from '../../../data/images/video-button.png'
import { HowWorksWrap, ImageWrap, VideoBtn, TitleWrapper } from './style'

const HowWorkArea = () => {
    const getHowWorskData = useStaticQuery(graphql`
        query WorksQuery {
            dataJson(id: {eq: "how-works-content"}) {
                title
                image {
                    childImageSharp {
                        fluid(maxWidth: 1045, maxHeight: 700, quality: 100, srcSetBreakpoints: 6) {
                            ...GatsbyImageSharpFluid_tracedSVG
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
                video_link
            }
        }
    `);
    const { title, image, video_link } = getHowWorskData.dataJson

    let video_arr, video_id, video_channel;
    if (video_link) {
        video_arr = video_link.split('=', -1);
        video_id = video_arr[1];
        video_channel = video_link.split(".")[1];
    }
    const [videoOpen, setVideoOpen] = useState(false);
    const modalVideoOpen = () => {
        setVideoOpen(true)
    }
    const modalVideoClose = () => {
        setVideoOpen(false)
    }
    return (
        <Fragment>
            <HowWorksWrap>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <TitleWrapper>
                                <SectionTitle
                                    title={title}
                                />
                                <p>
                                    <a
                                        href="https://www.youtube.com/playlist?list=PLtcyj5He1dqLHnV_jUjWk3elO5rQ1BSqm"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >See All Customization Video</a>
                                </p>
                            </TitleWrapper>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={10} xl={8}>
                            <ImageWrap>
                                <Image fluid={image.childImageSharp.fluid} alt="How works" />
                                <VideoBtn onClick={modalVideoOpen}>
                                    <img src={videoBtn} alt="button" />
                                    <p>play video</p>
                                </VideoBtn>
                            </ImageWrap>
                        </Col>
                    </Row>
                </Container>
            </HowWorksWrap>
            <ModalVideo
                channel={video_channel}
                videoId={video_id}
                isOpen={videoOpen}
                onClose={modalVideoClose}
            />
        </Fragment>
    )
}

export default HowWorkArea
