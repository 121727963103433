import styled from "styled-components";
import { space } from 'styled-system'
import { device } from '../../theme'

export const DemoWrap = styled.div`
    text-align: center;
    position: relative;
    ${space}
    .gatsby-image-wrapper{
        transition: .7s cubic-bezier(.26,.76,.3,.75);
    }
    h4{
        font-size: 20px;
        font-weight: 300;
        margin: 0;
        ${device.small}{
            font-size: 25px;
        }
    }
    a{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-indent: -99999px;
    }
    &:hover{
        .gatsby-image-wrapper{
            transform: translate3d(0,-10px,0);
        }
    }
`;